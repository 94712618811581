import { useContext, useEffect, useState } from 'react';
import authService from 'src/services/auth';
import { useLocation } from 'react-router-dom';
import AssistantChatContext from 'src/contexts/app/assistant-demo/AssistantChatContext';
import AssistantChat from 'src/components/assistant-demo';

interface DemoAssistantProps {
	language: string;
}

const DemoAssistant = ({ language }: DemoAssistantProps) => {
	const { handleAssistantChat } = useContext(AssistantChatContext);
	const { registerGuest } = authService;

	const [isAuthenticated, setIsAuthenticated] = useState(
		Boolean(localStorage.getItem('tokens')),
	);
	const { pathname } = useLocation();

	useEffect(() => {
		const handleStorageChange = () => {
			setIsAuthenticated(Boolean(localStorage.getItem('tokens')));
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	useEffect(() => {
		if (isAuthenticated) {
			handleAssistantChat(pathname, language);
		} else {
			registerGuest();
		}
	}, [isAuthenticated, pathname]);

	return <AssistantChat />;
};

export default DemoAssistant;
