import {
	IAuthResponse,
	IForgotPasswordFrom,
	ILoginForm,
	ISingUpFOrm,
	ITokens,
	IUser,
} from 'src/lib/schemas';
import http from './http';
import config from 'src/config';
const apiVersion = 'v1';
const authUrl = `/${apiVersion}/auth`;
const tokensKey = 'tokens';
const userKey = 'user';
const login = async (payload: ILoginForm): Promise<IUser | null> => {
	try {
		const { data } = await http.post<IAuthResponse>(
			`${authUrl}/login`,
			payload,
		);
		setTokens(data.tokens);
		setCurrentUser(data.user);
		return data.user;
	} catch (error: any) {
		if (error.response && error.response.data) {
			throw error.response.data;
		} else {
			throw error;
		}
	}
};
const signup = async (payload: ISingUpFOrm): Promise<IUser> => {
	const { data } = await http.post<IAuthResponse>(
		`${authUrl}/register`,
		payload,
	);
	return data.user;
};
const setTokens = (tokens: ITokens) => {
	localStorage.setItem(tokensKey, JSON.stringify(tokens));
};
const getTokens = (): ITokens | null => {
	const tokens = localStorage.getItem(tokensKey);
	if (!tokens) {
		return null;
	}
	const parsedTokens = JSON.parse(tokens) as ITokens;
	return parsedTokens;
};
const setCurrentUser = (user: IUser) => {
	localStorage.setItem(userKey, JSON.stringify(user));
};
const getCurrentUser = (): IUser | null => {
	const user = localStorage.getItem(userKey);
	if (!user) {
		return null;
	}
	const parsedUser = JSON.parse(user) as IUser;
	return parsedUser;
};
const isAuthenticated = () => {
	const token = localStorage.getItem(tokensKey);
	return !!token;
};
const forgotPassword = async (payload: IForgotPasswordFrom) => {
	await http.post(`${authUrl}/forgot-password`, payload);
};
const resetPassword = async (token: string, password: string) => {
	const { data } = await http.post(`${authUrl}/reset-password?token=${token}`, {
		password,
	});
	setTokens(data.tokens);
	setCurrentUser(data.user);
};
const acceptInvitation = async (token: string, password: string) => {
	const { data } = await http.post<IAuthResponse>(
		`${authUrl}/accept-invitation/${token}`,
		{ password },
	);
	setTokens(data.tokens);
	setCurrentUser(data.user);
};
const verifyEmail = async (token: string): Promise<IAuthResponse> => {
	const { data } = await http.post<IAuthResponse>(
		`${authUrl}/verify-email?token=${token}`,
	);
	setTokens(data.tokens);
	setCurrentUser(data.user);
	return data;
};
const sendVerificationEmail = async () => {
	await http.post(`${authUrl}/send-verification-email`);
};
const logout = async () => {
	cleanupSession();
};
const cleanupSession = () => {
	localStorage.removeItem(tokensKey);
	localStorage.removeItem(userKey);
};
const refreshToken = async () => {
	const tokens = getTokens();
	if (!tokens) {
		return;
	}
	const { data } = await http.post<IAuthResponse>(`${authUrl}/refresh-tokens`, {
		refreshToken: tokens.refresh.token,
	});
	setTokens(data.tokens);
	setCurrentUser(data.user);
	return data;
};

const registerGuest = async () => {
	// const accountId = config.user.guestId;
	// const accountId = '674f60e5c90e3989c480b1c8';
	// const { data } = await http.post<ITokens>(
	// 	`${authUrl}/guest-user?accountId=${accountId}`,
	// );
	// setTokens(data);
	// return data;

	const data = await login({
		email: 'mauro+distilleryguest@fusionos.ai',
		password: 'qThMpkpvWSpH1',
	});
	return data;
};

export default {
	login,
	signup,
	logout,
	cleanupSession,
	getTokens,
	isAuthenticated,
	getCurrentUser,
	forgotPassword,
	resetPassword,
	verifyEmail,
	sendVerificationEmail,
	refreshToken,
	acceptInvitation,
	registerGuest,
};
