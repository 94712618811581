import {
	Box,
	Image,
	Button,
	Text,
	Wrap,
	WrapItem,
	Icon,
} from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

interface ImagePreviewListProps {
	images: File[];
	errors: Record<string, string>;
	onRemove: (index: number) => void;
	onClick: (src: string) => void;
}

const ImagePreviewList: React.FC<ImagePreviewListProps> = ({
	images,
	errors,
	onRemove,
	onClick,
}) => (
	<Box>
		<Wrap mb={2}>
			{images.map((image, index) => (
				<WrapItem key={index} position="relative">
					<Box
						position="relative"
						width="50px"
						height="50px"
						borderRadius="md"
						overflow="visible"
						cursor="pointer"
						onClick={() => onClick(URL.createObjectURL(image))}
						border={errors[image.name] ? '2px solid red' : 'none'}
					>
						<Image
							src={URL.createObjectURL(image)}
							alt={`Selected Image ${index + 1}`}
							boxSize="45px"
							objectFit="cover"
							borderRadius="md"
						/>

						<Button
							size="xs"
							colorScheme="gray"
							position="absolute"
							top={-2}
							right={-2}
							borderRadius="full"
							minH="fit-content"
							p={0}
							onClick={(e) => {
								e.stopPropagation();
								onRemove(index);
							}}
							fontSize={15}
						>
							&times;
						</Button>
					</Box>
				</WrapItem>
			))}
		</Wrap>

		<Box my={2}>
			{images.map((image, index) =>
				errors[image.name] ? (
					<Text
						key={`error-${index}`}
						color="red.500"
						fontSize="sm"
						mt={1}
						whiteSpace="normal"
						wordBreak="break-word"
					>
						<Image
							src={URL.createObjectURL(image)}
							alt={`Selected Image ${index + 1}`}
							boxSize="15px"
							objectFit="cover"
							display="inline-block"
							borderRadius="full"
							mr={2}
						/>
						{errors[image.name]}
					</Text>
				) : null,
			)}
		</Box>
	</Box>
);

export default ImagePreviewList;
