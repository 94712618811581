import { Box, Flex, Input, IconButton, useDisclosure } from '@chakra-ui/react';
import { FormEvent, useContext, useRef, useState } from 'react';
import { HiOutlinePaperClip, HiPaperAirplane } from 'react-icons/hi2';
import DragAndDropContainer from '../common/images/DragAndDropContainer';
import { uploadFile } from 'src/services/fileUpload';
import useImage from 'src/hooks/useImage';
import ImagePreviewList from './hooks/ImagePreview';
import { ChatMessageEnum, ThreadRolesEnum } from 'src/lib/schemas';
import ZoomedImage from '../common/ZoomedImage';
import AssistantChatContext from 'src/contexts/app/assistant-demo/AssistantChatContext';

const Actions = () => {
	const [content, setContent] = useState('');
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [isSendingMessage, setIsSendingMessage] = useState(false);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const {
		thread,
		isMyAssistantWritting,
		shouldSubmitBeAble,
		isChatLoading,
		addMessage,
		sendMessage,
	} = useContext(AssistantChatContext);

	const {
		selectedImages,
		uploadErrors,
		addImages,
		removeImage,
		setUploadErrors,
		removeAllImages,
	} = useImage();

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		if ((!content && !selectedImages.length) || isMyAssistantWritting) return;

		setIsSendingMessage(true);
		setUploadErrors({});

		let imagesMarkdownText = '';
		const newUploadErrors: Record<string, string> = {};

		if (selectedImages.length) {
			const imageUploadPromises = selectedImages.map(async (image) => {
				try {
					const { imageUrl } = await uploadFile(image);
					return `![](${imageUrl})`;
				} catch (error: any) {
					newUploadErrors[image.name] =
						error?.response?.data?.message || 'Upload failed.';
					return null;
				}
			});

			const uploadedMarkdown = await Promise.all(imageUploadPromises);
			imagesMarkdownText = uploadedMarkdown.filter(Boolean).join(' ');

			if (Object.keys(newUploadErrors).length > 0) {
				setUploadErrors(newUploadErrors);
				setIsSendingMessage(false);
				return;
			}
		}

		const updatedContent =
			content + (imagesMarkdownText ? ` ${imagesMarkdownText}` : '');

		const payload = {
			user_message: updatedContent,
			thread_id: thread?.threadId,
		};

		addMessage({
			role: ThreadRolesEnum.User,
			raw_message: updatedContent,
			message_id: crypto.randomUUID(),
			threadId: thread?.threadId,
			splitted_content: [
				...(content
					? [
							{
								value: content,
								type: ChatMessageEnum.String,
								suggestion: false,
							},
					  ]
					: []),
				...selectedImages.map((file) => ({
					value: URL.createObjectURL(file),
					type: ChatMessageEnum.Image,
					suggestion: false,
				})),
			],
		});
		sendMessage(payload);
		setContent('');
		removeAllImages();
		setIsSendingMessage(false);
	};

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) addImages(Array.from(event.target.files));
		if (fileInputRef.current) fileInputRef.current.value = '';
	};

	const handleImageClick = (src: string) => {
		setSelectedImage(src);
		onOpen();
	};

	return (
		<>
			<Box
				bg="gray.100"
				py="24px"
				px="16px"
				pt={selectedImages.length ? '12px' : '24px'}
			>
				{selectedImages.length > 0 && (
					<ImagePreviewList
						images={selectedImages}
						errors={uploadErrors}
						onRemove={removeImage}
						onClick={handleImageClick}
					/>
				)}
				<DragAndDropContainer onDropFiles={addImages}>
					<form onSubmit={handleSubmit} style={{ width: '100%' }}>
						<Box
							borderWidth="1px"
							borderRadius="md"
							display="flex"
							alignItems="center"
							bg="white"
							pl={3}
						>
							<Input
								placeholder="Tell us..."
								variant="unstyled"
								flex="1"
								_focus={{ outline: 'none' }}
								h="40px"
								fontSize="14px"
								autoFocus
								minW="70%"
								value={content}
								onChange={(e) => setContent(e.target.value)}
							/>

							<Flex gap={0}>
								<IconButton
									aria-label="Attach"
									icon={
										<HiOutlinePaperClip
											style={{ width: '20px', height: '20px' }}
										/>
									}
									variant="blank"
									onClick={() =>
										document.getElementById('image-upload-multiple')?.click()
									}
								/>
								<Input
									id="image-upload-multiple"
									type="file"
									accept="image/*"
									multiple
									display="none"
									onChange={handleFileUpload}
									ref={fileInputRef}
								/>

								<IconButton
									aria-label="Send"
									icon={
										<HiPaperAirplane
											style={{ width: '20px', height: '20px' }}
										/>
									}
									isDisabled={isChatLoading || !shouldSubmitBeAble}
									isLoading={isSendingMessage}
									type="submit"
									variant="blank"
									color="orange.base"
								/>
							</Flex>
						</Box>
					</form>
				</DragAndDropContainer>
			</Box>
			{selectedImage && (
				<ZoomedImage
					isOpen={isOpen}
					onClose={onClose}
					imageSrc={selectedImage}
				/>
			)}
		</>
	);
};

export default Actions;
