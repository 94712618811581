export enum ThreadTypesEnum {
	Campaign = 'CAMPAIGN',
}

export enum ThreadRolesEnum {
	User = 'user',
	Assistant = 'assistant',
}

export enum ChatMessageEnum {
	String = 'String',
	Suggestion = 'Suggestion',
	Image = 'Image',
}

export interface IAssistantConfig {
	assistant_id: string;
	name: string;
	welcome_message: string;
	loading_messages: string[];
	default: boolean;
	validations: Record<string, any>;
	instructions: Record<string, any>;
	initial_prompts_by_country: InitialPrompt[];
}

export interface InitialPrompt {
	code: string;
	name: string;
	prompt: string;
	avatar: string;
	lang: string;
}

export interface IAssistantCreativeData {
	id: string;
	channel: string;
	variant: string;
}

export interface IField {
	name: string;
	displayName: string;
	content: string;
	id: string;
	variant?: string;
	channel?: string;
	setValue?: (field: string, value: string) => void;
	setValueV2?: (value: string) => void;
}

export interface ISuggestion {
	value: string;
}

export interface ISplittedContent {
	value: string;
	type: string;
	suggestion: boolean;
}

export interface IMessage {
	threadId?: string;
	thread_id?: string;
	property?: string;
	role: string;
	message_id?: string;
	status?: 'processing' | 'successful';
	splitted_content?: ISplittedContent[];
	raw_message?: string;
	error?: boolean;
}

export interface IThread {
	threadId?: string;
	assistantId?: string;
	property?: string | number;
	reference?: string;
	sub_reference?: string;
	conversation: Array<IMessage>;
	language?: string;
	pathname?: string;
	avatar?: string;
	lang?: string;
}

export interface IThreadPayload {
	user_message: string;
	current_content?: string;
	scope?: IGetThreadParams;
	thread_id?: string;
	property?: string | number;
}

export interface IThreadMessageParams {
	assistantId: string;
	payload: IThreadPayload;
	threadId?: string;
	lang?: string;
	countryCode?: string;
}

export interface IMessageResponse {
	response: IMessage;
	// {
	// 	role: string;
	// 	thread_id: string;
	// 	property?: string;
	// 	suggestions?: ISuggestion;
	// 	splitted_content?: ISplittedContent[];
	// 	message_id: string;
	// 	status?: 'processing' | 'successful';
	// 	extra?: {
	// 		callback: string;
	// 		status: 'processing' | 'successful';
	// 	};
	// };
}

export interface IGetThreadParams {
	type?: string;
	reference?: string;
	sub_reference?: string;
	property?: any;
}

export interface IThreadResponse {
	scope: {
		thread_id: string;
		assistant_id: string;
	};
	conversation: Array<IMessage>;
}

export interface IOpenAssistantChat {
	fieldParams: IField;
	threadParams: IGetThreadParams;
	ref?: HTMLDivElement | null;
}
