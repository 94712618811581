import { FC, useEffect, useState } from 'react';
import AssistantChatContext from './AssistanChatContext';
import {
	IField,
	IMessage,
	IOpenAssistantChat,
	IThread,
	IThreadPayload,
} from 'src/lib/schemas';
import {
	getThreadByReference,
	getAsyncMessageUrl,
	sendMessage as sendMessageService,
	startThread,
	getThreadById,
} from 'src/services/assistant';
import { toastError } from 'src/services/toast';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { processCallback } from 'src/lib/utils/processCallback';
import config from 'src/config';

enum ResponseStatus {
	Seen,
	Pending,
	Unread,
}

interface AssistantChatContextProps {
	children: React.ReactNode;
}
declare global {
	interface Window {
		Intercom: any;
	}
}

// export const threadInitialValue: IThread = {
// 	conversation: [
// 		{
// 			role: ThreadRolesEnum.Assistant,
// 			splitted_content: [
// 				{
// 					value: 'How can I help you?',
// 					type: ChatMessageEnum.String,
// 					suggestion: false,
// 				},
// 			],
// 		},
// 	],
// };

export const threadInitialValue: IThread = {
	conversation: [],
};

const DEFAULT_ASSISTANT = config.app.assistantId;

const AssistantChatProvider: FC<AssistantChatContextProps> = ({ children }) => {
	const [isChatOpen, setIsChatOpen] = useState(false);
	const [isChatCollapsed, setIsChatCollapsed] = useState(false);
	const [isChatLoading, setIsChatLoading] = useState(false);
	const [isIntercomVisible, setIsIntercomVisible] = useState(false);
	const [isExecutionOnCourse, setIsExecutionOnCourse] = useState(false);
	const [field, setField] = useState<IField | null>(null);
	const [thread, setThread] = useState<IThread | null>(threadInitialValue);
	const [threads, setThreads] = useState<Record<string, string>>({});
	const [isInitialized, setIsInitialized] = useState(false);
	const [fieldRef, setFieldRef] = useState<HTMLDivElement | null>(null);
	const [responseMessage, setResponseMessage] = useState<IMessage | null>(null);
	const [requestMessages, setRequestMessages] = useState<Array<IThreadPayload>>(
		[],
	);
	const [pendingMessages, setPendingMessages] = useState<IMessage[]>([]);
	const [responseStatus, setResponseStatus] = useState(ResponseStatus.Seen);
	const location = useLocation();

	const hasThread = thread?.threadId;

	const isPendingMessage = thread?.conversation.some(
		(message) => message.status === 'processing',
	);

	useEffect(() => {
		const savedThreads = JSON.parse(localStorage.getItem('threads') || '{}');
		setThreads(savedThreads);
	}, []);

	const addThread = (path: string, threadId: string) => {
		setThreads((prev) => {
			const updatedThreads = { ...prev, [path]: threadId };
			localStorage.setItem('threads', JSON.stringify(updatedThreads));
			return updatedThreads;
		});
	};

	const getThread = (path: string): string | undefined => {
		return threads[path];
	};

	const removeThread = (path: string) => {
		setThreads((prev) => {
			const { [path]: _, ...rest } = prev;
			localStorage.setItem('threads', JSON.stringify(rest));
			return rest;
		});
	};

	const clearThreads = () => {
		setThreads({});
		localStorage.removeItem('threads');
	};

	const handleAssistantChat = async (pathname: string) => {
		const existingThreadId = getThread(pathname);

		if (existingThreadId) {
			openGeneralAssistantChat(existingThreadId);
		} else {
			try {
				const newThreadId = await startNewConversation();
				addThread(pathname, newThreadId);
			} catch (error) {
				console.error('Failed to start a new conversation:', error);
			}
		}
	};

	const handleCloseAssistant = (pathname: string) => {
		removeThread(pathname);
		resetThread();
	};

	useEffect(() => {
		// closeChat();
		window.Intercom &&
			window.Intercom('onShow', () => {
				setIsIntercomVisible(true);
			});
	}, [location]);

	useEffect(() => {
		if (thread?.threadId) {
			localStorage.setItem('assistantChatThreadId', thread.threadId);
		}
	}, [thread?.threadId]);

	// useEffect(() => {
	// 	if (isInitialized) {
	// 		openGeneralAssistantChat();
	// 	}
	// }, [isInitialized]);

	useEffect(() => {
		if (!responseMessage) return;
		if (isCurrentChat) {
			addMessage(responseMessage);
		}
	}, [responseMessage]);

	useEffect(() => {
		const pendingMessage = thread?.conversation.find(
			(message) => message.status === 'processing',
		);
		pendingMessage && setPendingMessages([pendingMessage]);
	}, [isPendingMessage]);

	useEffect(() => {
		if (pendingMessages.length) {
			pendingMessages.forEach(
				(message) =>
					message.message_id && handleAsyncMessage(message.message_id),
			);
		}
	}, [pendingMessages]);

	const isCurrentChat =
		thread?.threadId === responseMessage?.threadId &&
		thread?.property === responseMessage?.property;

	const hasUnseenMessages = responseStatus === ResponseStatus.Unread;

	const shouldSubmitBeAble =
		!isPendingMessage &&
		(!requestMessages.length ||
			thread?.threadId !== _.last(requestMessages)?.thread_id);

	const isMyAssistantWritting =
		isPendingMessage ||
		(requestMessages.length > 0 &&
			_.last(requestMessages)?.thread_id === thread?.threadId &&
			_.last(requestMessages)?.scope?.property === thread?.property);

	const openChat = async () => {
		// closeChat();
		setIsChatCollapsed(false);
		setIsChatOpen(true);
		// resetThread();
		setResponseStatus(ResponseStatus.Seen);
		hideIntercomMessenger();
		const savedThreadId = localStorage.getItem('assistantChatThreadId');
		if (savedThreadId) {
			try {
				const { scope, conversation } = await getThreadById(savedThreadId);

				const { thread_id: threadId, assistant_id: assistantId } = scope;

				const updatedThread = {
					threadId,
					assistantId,
					conversation,
				};

				setThread(updatedThread);
			} catch (error) {
				toastError(error);
			} finally {
				setIsInitialized(true);
			}
		} else {
			setIsInitialized(true);
		}
	};

	const hideIntercomMessenger = () => {
		if (!window.Intercom) return;

		window.Intercom('update', {
			hide_default_launcher: true,
		});
	};
	const showIntercomMessenger = () => {
		if (!isIntercomVisible || !window.Intercom) return;

		window.Intercom('update', {
			hide_default_launcher: false,
		});
	};
	const closeChat = () => {
		// resetThread();
		setField(null);
		setIsChatOpen(false);
		showIntercomMessenger();
	};

	const resetThread = () => {
		setThread(threadInitialValue);
		localStorage.removeItem('assistantChatThreadId');
	};

	const addMessage = (message: IMessage) => {
		setThread((prevState) => ({
			...prevState,
			id: message.threadId,
			conversation: [...prevState!.conversation, message],
		}));
	};

	const openAssistantChat = async ({
		fieldParams,
		threadParams,
		ref,
	}: IOpenAssistantChat) => {
		openChat();
		setIsChatLoading(true);
		setFieldRef(ref ?? null);
		const { type, reference, sub_reference } = threadParams!;
		const { id: property } = fieldParams!;
		let updatedThread = _.cloneDeep(thread);
		try {
			const { scope, conversation } = await getThreadByReference({
				type,
				reference,
				sub_reference,
				property,
			});
			const { thread_id: threadId, assistant_id: assistantId } = scope;
			const updatedConversation =
				conversation.length > 0
					? conversation
					: threadInitialValue.conversation;
			updatedThread = {
				threadId,
				assistantId,
				property,
				conversation: updatedConversation,
				reference,
				sub_reference,
			};
		} catch (error) {
			updatedThread = {
				...threadInitialValue,
				reference,
				sub_reference,
				property,
			};
		}
		setIsChatLoading(false);
		setField(fieldParams);
		setThread(updatedThread);
	};

	const openGeneralAssistantChat = async (currentPath: string) => {
		console.log('Opening Assistant Chat for:', currentPath);

		// Obtener los threads existentes del localStorage
		const threads = JSON.parse(localStorage.getItem('threads') || '{}');
		console.log('Current Threads in localStorage:', threads);

		const existingThreadId = threads[currentPath];
		console.log('Existing Thread ID:', existingThreadId);

		if (existingThreadId) {
			// Si existe un thread, cargarlo
			try {
				const { scope, conversation } = await getThreadById(existingThreadId);
				const { thread_id: threadId, assistant_id: assistantId } = scope;

				const updatedThread: IThread = {
					threadId,
					assistantId,
					conversation,
				};

				setThread(updatedThread);
				setIsChatOpen(true);
				console.log('Loaded existing thread:', updatedThread);
			} catch (error) {
				console.error('Failed to load existing thread:', error);
				toastError('Failed to load the assistant chat.');
			}
		} else {
			// Si no existe un thread, crear uno nuevo
			try {
				const { response } = await startThread(undefined, 'Hi');
				const newThreadId = response.thread_id;

				const newThread: IThread = {
					threadId: newThreadId,
					conversation: [
						{
							role: response.role,
							threadId: newThreadId,
							splitted_content: response.splitted_content,
							raw_message: response.raw_message,
						},
					],
				};

				// Guardar el nuevo thread en el estado y localStorage
				setThread(newThread);
				localStorage.setItem(
					'threads',
					JSON.stringify({ ...threads, [currentPath]: newThreadId }),
				);

				setIsChatOpen(true);
				console.log('Started new thread:', newThread);
			} catch (error) {
				console.error('Failed to start a new conversation:', error);
				toastError('Failed to start a new conversation.');
			}
		}
	};

	const markMessageAsSeen = () => setResponseStatus(ResponseStatus.Seen);

	const handleAsyncMessage = async (messageId: string) => {
		if (!thread?.threadId) return;
		setIsExecutionOnCourse(true);
		const callback = getAsyncMessageUrl({
			threadId: thread?.threadId,
			messageId,
		});
		try {
			await processCallback(callback);
			const { scope, conversation } = await getThreadById(thread.threadId);

			const { thread_id: threadId, assistant_id: assistantId } = scope;

			const updatedThread = {
				threadId,
				assistantId,
				conversation,
			};

			setThread(updatedThread);

			setPendingMessages(
				conversation.filter((message) => message.status === 'processing'),
			);
		} catch (error) {
			toastError(error);
		} finally {
			setIsExecutionOnCourse(false);
		}
	};

	const sendMessage = async (payload: IThreadPayload) => {
		setRequestMessages((prevState) => [...prevState, payload]);
		setResponseStatus(ResponseStatus.Pending);
		try {
			const { response } = await sendMessageService({
				assistantId: thread?.assistantId || DEFAULT_ASSISTANT,
				payload,
				threadId: payload.thread_id,
			});
			response.status === 'processing' &&
				setPendingMessages((prevState) => [...prevState, response]);
			const message: IMessage = {
				role: response.role,
				threadId: response.thread_id,
				thread_id: response.thread_id,
				splitted_content: response.splitted_content,
				message_id: response.message_id,
				status: response.status,
				raw_message: response.raw_message,
				error: false,
			};
			setResponseStatus(ResponseStatus.Unread);
			setResponseMessage(message);

			if (!thread?.threadId) {
				setThread((prevState) => ({
					...prevState,
					threadId: response.thread_id,
					conversation: [...prevState!.conversation],
				}));
			}
		} catch (error) {
			toastError(
				'The message could not be sent. Please refresh the page and try again.',
			);
			setThread((prevState) => {
				if (!prevState || prevState.conversation.length === 0) return prevState;

				const updatedConversation = [...prevState.conversation];
				const lastIndex = updatedConversation.length - 1;

				updatedConversation[lastIndex] = {
					...updatedConversation[lastIndex],
					error: true,
				};

				return {
					...prevState,
					conversation: updatedConversation,
				};
			});
		}
		setRequestMessages((prevState) =>
			prevState.filter((message) => message !== payload),
		);
	};

	const retryMessage = async (message: IMessage) => {
		const payload: IThreadPayload = {
			user_message: message.raw_message ?? '',
			thread_id: message.threadId ?? message.message_id,
		};
		await sendMessage(payload);
	};

	const applySuggestion = (value: string) => {
		field?.setValue?.(field.name, value);
		field?.setValueV2?.(value);
		const input = fieldRef?.lastChild as HTMLElement;
		input?.focus();
		input?.scrollIntoView({
			block: 'center',
			behavior: 'instant',
		});
	};

	const startNewConversation = async (): Promise<string> => {
		try {
			setThread(threadInitialValue);
			setIsChatCollapsed(false);
			setIsChatOpen(true);
			setIsChatLoading(true);

			const { response } = await startThread(undefined, 'Hi');
			const newThread: IThread = {
				threadId: response.thread_id,
				conversation: [
					{
						role: response.role,
						threadId: response.thread_id,
						splitted_content: response.splitted_content,
						raw_message: response.raw_message,
					},
				],
			};

			setThread(newThread);
			setIsChatLoading(false);
			setResponseStatus(ResponseStatus.Seen);

			return response.thread_id ?? response.threadId ?? '';
		} catch (error) {
			setIsChatLoading(false);
			toastError(error || 'Failed to start a new conversation.');
			throw error;
		}
	};

	return (
		<AssistantChatContext.Provider
			value={{
				isChatOpen,
				isChatCollapsed,
				field,
				thread,
				isMyAssistantWritting,
				isChatLoading,
				shouldSubmitBeAble,
				requestMessages,
				sendMessage,
				setField,
				openChat,
				closeChat,
				setIsChatCollapsed,
				resetThread,
				addMessage,
				openAssistantChat,
				openGeneralAssistantChat,
				applySuggestion,
				isCurrentChat,
				hasUnseenMessages,
				markMessageAsSeen,
				startNewConversation,
				retryMessage,
				addThread,
				getThread,
				removeThread,
				clearThreads,
				handleAssistantChat,
			}}
		>
			{children}
		</AssistantChatContext.Provider>
	);
};

export default AssistantChatProvider;
