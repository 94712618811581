import { FC, useContext } from 'react';
import { Flex, Text, HStack, Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { IoSparklesSharp } from 'react-icons/io5';
import AssistantChatContext from 'src/contexts/app/assistant-demo/AssistantChatContext';
import { useLocation } from 'react-router-dom';

interface IHeader {
	fieldName?: string;
	currentContent?: string;
	height?: string | number;
	toggleCollapse?: () => void;
}

const Header: FC<IHeader> = ({ fieldName, height }) => {
	const { startNewConversation } = useContext(AssistantChatContext);
	const { pathname } = useLocation();

	return (
		<>
			<Flex
				height={height}
				bg="white"
				align="center"
				p={3}
				justifyContent="space-between"
				borderBottomWidth={1}
				borderTopWidth={1}
			>
				<Flex color="orange.base" gap={2} pl={2} alignItems="center">
					<IoSparklesSharp style={{ width: '24px', height: '24px' }} />
					<Text
						color="orange.base"
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
						fontSize="16px"
						fontWeight={500}
						mt={0.5}
					>
						Assistant {fieldName && `- ${fieldName}`}
					</Text>
				</Flex>
				<HStack gap={1}>
					<Button
						leftIcon={<AddIcon />}
						size="sm"
						fontSize="small"
						variant="outline"
						onClick={() => startNewConversation(pathname)}
					>
						Start new conversation
					</Button>
				</HStack>
			</Flex>
		</>
	);
};

export default Header;
