import React, { FC, useState, useEffect } from 'react';
import AssistantChatContext from './AssistantChatContext';
import {
	IAssistantConfig,
	IMessage,
	IThread,
	IThreadPayload,
} from 'src/lib/schemas';
import {
	getThreadById,
	startThread,
	sendMessage as sendMessageService,
	getAsyncMessageUrl,
	getAssistantConfig,
} from 'src/services/assistant';
import { toastError } from 'src/services/toast';
import { last } from 'lodash';
import { processCallback } from 'src/lib/utils/processCallback';

export const threadInitialValue: IThread = {
	conversation: [],
};

const AssistantChatProvider: FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [config, setConfig] = useState<IAssistantConfig>();
	const [isChatOpen, setIsChatOpen] = useState(false);
	const [isChatLoading, setIsChatLoading] = useState(false);
	const [thread, setThread] = useState<IThread | null>(threadInitialValue);
	const [threads, setThreads] = useState<Record<string, string>>({});
	const [languageCode, setLanguageCode] = useState<string>();
	const [imageCache, setImageCache] = useState<Set<string>>(new Set());
	const [requestMessages, setRequestMessages] = useState<Array<IThreadPayload>>(
		[],
	);
	const [pendingMessages, setPendingMessages] = useState<IMessage[]>([]);

	const isPendingMessage = thread?.conversation.some(
		(message) => message.status === 'processing',
	);

	const shouldSubmitBeAble =
		!isPendingMessage &&
		(!requestMessages.length ||
			thread?.threadId !== last(requestMessages)?.thread_id);

	const isMyAssistantWritting =
		isPendingMessage ||
		(requestMessages.length > 0 &&
			last(requestMessages)?.thread_id === thread?.threadId);

	const hideIntercomMessenger = () => {
		if (!window.Intercom) return;

		window.Intercom('update', {
			hide_default_launcher: true,
		});
	};

	useEffect(() => {
		hideIntercomMessenger();
	}, []);

	useEffect(() => {
		if (!thread?.threadId || config) return;

		const fetchConfig = async () => {
			try {
				const configData = await getAssistantConfig();
				setConfig(configData);
			} catch (error) {
				console.error('Failed to load assistant configuration:', error);
			}
		};

		fetchConfig();
	}, [thread?.threadId]);

	useEffect(() => {
		// Load threads from localStorage on mount
		const savedThreads = JSON.parse(localStorage.getItem('threads') || '{}');
		setThreads(savedThreads);
	}, []);

	useEffect(() => {
		const pendingMessage = thread?.conversation.find(
			(message) => message.status === 'processing',
		);
		pendingMessage && setPendingMessages([pendingMessage]);
	}, [isPendingMessage]);

	useEffect(() => {
		if (pendingMessages.length) {
			pendingMessages.forEach(
				(message) =>
					message.message_id && handleAsyncMessage(message.message_id),
			);
		}
	}, [pendingMessages]);

	const addThread = (path: string, threadId: string) => {
		setThreads((prev) => {
			const updatedThreads = { ...prev, [path]: threadId };
			localStorage.setItem('threads', JSON.stringify(updatedThreads));
			return updatedThreads;
		});
	};

	const getThread = (path: string): string | undefined => threads[path];

	const removeThread = (path: string) => {
		setThreads((prev) => {
			const { [path]: _, ...rest } = prev;
			localStorage.setItem('threads', JSON.stringify(rest));
			return rest;
		});
	};

	const openGeneralAssistantChat = async (
		existingThreadId: string,
		language: string,
	) => {
		try {
			setIsChatLoading(true);
			const { scope, conversation } = await getThreadById(existingThreadId);
			const { thread_id: threadId, assistant_id: assistantId } = scope;
			let configData = config;
			if (!configData) {
				configData = await getAssistantConfig();
			}
			const avatar = getAvatar(language, configData);

			const updatedThread: IThread = {
				threadId,
				assistantId,
				conversation,
				language,
				avatar,
			};

			setThread(updatedThread);
			setIsChatOpen(true);
		} catch (error) {
			console.error('Failed to load existing thread:', error);
			toastError('Failed to load the assistant chat.');
		} finally {
			setIsChatLoading(false);
		}
	};

	const getInitialPrompt = (
		countryCode: string,
		configData: IAssistantConfig,
	) =>
		configData?.initial_prompts_by_country.find((c) => c.code === countryCode)
			?.prompt;

	const getLanguageCode = (countryCode: string, configData: IAssistantConfig) =>
		configData?.initial_prompts_by_country.find((c) => c.code === countryCode)
			?.lang;

	const cacheImage = (src: string) => {
		if (!src || imageCache.has(src)) return;

		const img = new Image();
		img.src = src;
		img.onload = () => {
			setImageCache((prev) => new Set(prev).add(src));
		};
	};

	const getAvatar = (
		countryCode: string,
		configData: IAssistantConfig,
	): string | undefined => {
		if (!configData) {
			console.warn('Configuration not ready yet.');
			return undefined;
		}

		const avatar = configData.initial_prompts_by_country.find(
			(c) => c.code === countryCode,
		)?.avatar;

		if (avatar) cacheImage(avatar);

		return avatar;
	};

	const startNewConversation = async (
		pathname: string,
		countryCode: string = thread?.language ?? 'US',
	): Promise<string> => {
		try {
			setIsChatLoading(true);
			let configData = config;
			if (!configData) {
				configData = await getAssistantConfig();
			}
			const prompt = getInitialPrompt(countryCode, configData);
			const avatar = getAvatar(countryCode, configData);
			const languageCode = getLanguageCode(countryCode, configData);
			setLanguageCode(languageCode);
			const { response } = await startThread(
				undefined,
				prompt ?? 'Hello',
				languageCode ?? 'US',
				countryCode,
			);
			const newThread: IThread = {
				threadId: response.thread_id,
				language: countryCode,
				pathname,
				avatar,
				lang: languageCode,
				conversation: [
					{
						role: response.role,
						threadId: response.thread_id,
						splitted_content: response.splitted_content,
						raw_message: response.raw_message,
					},
				],
			};
			setThread(newThread);
			addThread(pathname, newThread.threadId ?? '');
			setIsChatLoading(false);
			return response.thread_id ?? response.threadId ?? '';
		} catch (error) {
			setIsChatLoading(false);
			toastError(error || 'Failed to start a new conversation.');
			throw error;
		}
	};

	const addMessage = (message: IMessage) => {
		setThread((prevState) => ({
			...prevState,
			id: message.threadId,
			conversation: [...prevState!.conversation, message],
		}));
	};

	const sendMessage = async (payload: IThreadPayload) => {
		setRequestMessages((prevState) => [...prevState, payload]);
		try {
			const { response } = await sendMessageService({
				assistantId: thread?.assistantId || '',
				payload,
				threadId: payload.thread_id,
				lang: languageCode,
				countryCode: thread?.language,
			});
			response.status === 'processing' &&
				setPendingMessages((prevState) => [...prevState, response]);
			const message: IMessage = {
				role: response.role,
				threadId: response.thread_id,
				splitted_content: response.splitted_content,
				message_id: response.message_id,
				status: response.status,
				raw_message: response.raw_message,
				error: false,
			};

			setThread((prevState) => ({
				...prevState,
				conversation: [...(prevState?.conversation || []), message],
			}));
		} catch (error) {
			toastError('Failed to send the message.');
			setThread((prevState) => {
				if (!prevState || prevState.conversation.length === 0) return prevState;

				const updatedConversation = [...prevState.conversation];
				const lastIndex = updatedConversation.length - 1;

				updatedConversation[lastIndex] = {
					...updatedConversation[lastIndex],
					error: true,
				};

				return {
					...prevState,
					conversation: updatedConversation,
				};
			});
		}
		setRequestMessages((prevState) =>
			prevState.filter((message) => message !== payload),
		);
	};

	const handleAsyncMessage = async (messageId: string) => {
		if (!thread?.threadId) return;
		const callback = getAsyncMessageUrl({
			threadId: thread?.threadId,
			messageId,
		});
		try {
			await processCallback(callback);
			const { scope, conversation } = await getThreadById(thread.threadId);

			const { thread_id: threadId, assistant_id: assistantId } = scope;

			const updatedThread = {
				threadId,
				assistantId,
				conversation,
				avatar: thread.avatar,
			};

			setThread(updatedThread);

			setPendingMessages(
				conversation.filter((message) => message.status === 'processing'),
			);
		} catch (error) {
			toastError(error);
		}
	};

	const handleAssistantChat = async (pathname: string, language: string) => {
		const existingThreadId = getThread(pathname);

		if (existingThreadId) {
			openGeneralAssistantChat(existingThreadId, language);
		} else {
			try {
				const newThreadId = await startNewConversation(pathname, language);
			} catch (error) {
				console.error('Failed to start a new conversation:', error);
			}
		}
	};

	const retryMessage = async (message: IMessage) => {
		const payload: IThreadPayload = {
			user_message: message.raw_message ?? '',
			thread_id: message.threadId ?? message.message_id,
		};
		await sendMessage(payload);
	};

	return (
		<AssistantChatContext.Provider
			value={{
				isChatOpen,
				isChatLoading,
				thread,
				isMyAssistantWritting,
				shouldSubmitBeAble,
				openGeneralAssistantChat,
				startNewConversation,
				addMessage,
				sendMessage,
				handleAssistantChat,
				addThread,
				getThread,
				removeThread,
				retryMessage,
				imageCache,
			}}
		>
			{children}
		</AssistantChatContext.Provider>
	);
};

export default AssistantChatProvider;
