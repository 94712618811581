import { FC, useEffect, useRef, useState } from 'react';
import { Box, Image, List, ListItem, Skeleton, Text } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface IMessageProps {
	content: string;
	onImageClick?: (src: string) => void;
	onContentResize?: () => void;
}

const Message: FC<IMessageProps> = ({
	content,
	onImageClick,
	onContentResize,
}) => {
	const contentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!contentRef.current || !onContentResize) return;

		const resizeObserver = new ResizeObserver(() => {
			onContentResize();
		});

		resizeObserver.observe(contentRef.current);

		return () => {
			resizeObserver.disconnect();
		};
	}, [onContentResize]);

	return (
		<div ref={contentRef}>
			<ReactMarkdown
				remarkPlugins={[remarkGfm]}
				components={{
					img: ({ src, alt }) => (
						<LazyImage
							src={src as string}
							alt={alt}
							onImageClick={onImageClick}
						/>
					),
					p: ({ children }) => <Text fontSize="14px">{children}</Text>,
					ul: ({ children }) => (
						<List spacing={2} styleType="disc" pl={4}>
							{children}
						</List>
					),
					ol: ({ children }) => (
						<List as="ol" spacing={2} styleType="decimal" pl={4}>
							{children}
						</List>
					),
					li: ({ children }) => <ListItem>{children}</ListItem>,
				}}
			>
				{content}
			</ReactMarkdown>
		</div>
	);
};

const LazyImage: FC<{
	src: string;
	alt?: string;
	onImageClick?: (src: string) => void;
}> = ({ src, alt, onImageClick }) => {
	const [isLoaded, setIsLoaded] = useState(false);

	return (
		<Box
			position="relative"
			borderRadius="lg"
			overflow="hidden"
			cursor="pointer"
			maxW="200px"
			minW="200px"
		>
			{!isLoaded && <Skeleton h="150px" w="full" />}

			<Image
				src={src}
				alt={alt}
				onLoad={() => setIsLoaded(true)}
				onClick={onImageClick ? () => onImageClick(src) : undefined}
				display={isLoaded ? 'block' : 'none'}
				borderRadius="lg"
				objectFit="cover"
				transition="opacity 0.3s ease-in-out"
			/>
		</Box>
	);
};

export default Message;
