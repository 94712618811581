import { Navigate } from 'react-router-dom';
import DemoAssistant from 'src/pages/poc';
import { PublicLayout } from 'src/layout';

const getPublicRoutes = () => ({
	element: <PublicLayout />,
	children: [
		{
			path: '/assistants/distillery-1',
			element: <DemoAssistant language="US" />,
		},
		{
			path: '/assistants/distillery-2',
			element: <DemoAssistant language="CA" />,
		},
		{
			path: '/assistants/distillery-3',
			element: <DemoAssistant language="JP" />,
		},
		{
			path: '*',
			element: <Navigate to="/assistants/distillery-1" replace />,
		},
	],
});

export default getPublicRoutes;
