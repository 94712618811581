import React, { DragEvent, useState } from 'react';
import { Box } from '@chakra-ui/react';

interface DragAndDropContainerProps {
	onDropFiles: (files: File[]) => void;
	children?: React.ReactNode;
}

const DragAndDropContainer: React.FC<DragAndDropContainerProps> = ({
	onDropFiles,
	children,
}) => {
	const [isDraggingOver, setIsDraggingOver] = useState(false);

	const handleDrop = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setIsDraggingOver(false);

		if (event.dataTransfer.files) {
			const files = Array.from(event.dataTransfer.files);
			onDropFiles(files);
		}
	};

	const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setIsDraggingOver(true);
	};

	const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setIsDraggingOver(false);
	};

	return (
		<Box
			onDrop={handleDrop}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			borderRadius="md"
			textAlign="center"
			transition="all 0.2s ease-in-out"
			border="dashed"
			borderColor={isDraggingOver ? 'blue.200' : 'transparent'}
			boxShadow={isDraggingOver ? '0 0 0 2px blue.500' : 'none'}
			borderWidth={1}
		>
			{children}
		</Box>
	);
};

export default DragAndDropContainer;
