import {
	useState,
	FC,
	ReactNode,
	useCallback,
	useEffect,
	useContext,
} from 'react';
import TemplateContext, {
	ILayerSpec,
	IPayloadsByCase,
	ITemplate,
	IUpdatePayload,
} from './TemplatesContext';
import { getTemplatesByIndustry } from 'src/services/templates';
import { toastError } from 'src/services/toast';
import { ILayer } from 'src/lib/schemas/account/template/TemplateSchema';
import httpAppsai from 'src/services/http-appsai';
import { IIndustry, getIndustries } from 'src/services/industries';
import UserContext from '../UserContext';

interface TemplateProviderProps {
	children: ReactNode;
}

export const TemplateProvider: FC<TemplateProviderProps> = ({ children }) => {
	const [layers, setLayers] = useState<ILayer[]>([]);
	const [loadingLayers, setLoadingLayers] = useState(false);
	const [accountTemplates, setAccountTemplates] = useState<ITemplate[]>([]);
	const [platformTemplates, setPlatformTemplates] = useState<ITemplate[]>([]);
	const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(
		null,
	);
	const { user, account } = useContext(UserContext);
	const [isOptionsReady, setIsOptionsReady] = useState<boolean>(false);
	const [styles, setStyles] = useState<string[]>([]);
	const [seasons, setSeasons] = useState<string[]>([]);
	const [industries, setIndustries] = useState<IIndustry[]>([]);
	const [industriesLoaded, setIndustriesLoaded] = useState<boolean>(false);

	const [selectedTemplateIds, setSelectedTemplateIds] = useState<string[]>([]);
	const [payloads, setPayloads] = useState<IUpdatePayload[]>([]);
	const [payloadsByCase, setPayloadsByCaseState] = useState<IPayloadsByCase[]>(
		[],
	);
	const [templatesLoaded, setTemplatesLoaded] = useState<boolean>(false);
	const [selectedIndustry, setSelectedIndustry] = useState<IIndustry | null>(
		null,
	);

	const [isSelectTemplateModalOpen, setSelectTemplateModalOpen] =
		useState(false);
	const [hasFetchedLayers, setHasFetchedLayers] = useState(false);
	const [configuredLayers, setConfiguredLayers] = useState<
		{ variationId: string; layerSpec: ILayerSpec[] }[]
	>([]);

	const handleSetPayloadsByCase = (
		caseId: number,
		newPayload: IUpdatePayload,
		isRegenerate: boolean,
	) => {
		setPayloadsByCaseState((prevPayloadsByCase) => {
			const existingCase = prevPayloadsByCase.find(
				(payloadCase) => payloadCase.idCase === caseId,
			);

			if (existingCase) {
				return prevPayloadsByCase.map((payloadCase) => {
					if (payloadCase.idCase === caseId) {
						if (isRegenerate) {
							return {
								...payloadCase,
								payloadList: payloadCase.payloadList.map((payload) =>
									payload.variationId === newPayload.variationId
										? newPayload
										: payload,
								),
							};
						}
						return {
							...payloadCase,
							payloadList: [...payloadCase.payloadList, newPayload],
						};
					}
					return payloadCase;
				});
			} else {
				return [
					...prevPayloadsByCase,
					{ idCase: caseId, payloadList: [newPayload] },
				];
			}
		});
	};

	const fetchIndustries = useCallback(async () => {
		try {
			const industriesData = await getIndustries();
			setIndustries(industriesData);
			setIndustriesLoaded(true);
		} catch (error) {
			console.error('Error loading industries:', error);
		}
	}, []);

	const fetchTemplatesByScope = useCallback(async (scope?: IIndustry) => {
		try {
			const { results: allTemplates } = await getTemplatesByIndustry(scope);

			const filteredAccountTemplates = allTemplates.filter(
				(template) => template.scope === 'account',
			);

			const filteredPlatformTemplates = allTemplates.filter(
				(template) => template.scope === 'platform',
			);

			setAccountTemplates(filteredAccountTemplates);
			setPlatformTemplates(filteredPlatformTemplates);

			const allStyles = new Set<string>();
			const allSeasons = new Set<string>();

			allTemplates.forEach((template) => {
				if (template.attributes?.style) {
					allStyles.add(template.attributes.style);
				}
				if (template.attributes?.season) {
					allSeasons.add(template.attributes.season);
				}
			});

			setStyles(Array.from(allStyles));
			setSeasons(Array.from(allSeasons));

			setTemplatesLoaded(true);
		} catch (error: any) {
			toastError(
				error.message || `Failed to fetch templates for industry ${scope}`,
			);
		}
	}, []);

	const resetSelectedTemplate = () => {
		setSelectedTemplate(null);
		setSelectedTemplateIds([]);
	};

	const fetchLayers = async () => {
		if (
			!selectedTemplate?.variations ||
			selectedTemplate.variations.length === 0
		) {
			return;
		}

		setLoadingLayers(true);
		setHasFetchedLayers(true);

		try {
			const { data: responseData } = await httpAppsai.post(
				'/images/get-layers',
				{
					inputs: {
						layeredFile: selectedTemplate.variations[0].layeredFile,
						copyImages: false,
					},
				},
			);

			const mappedLayers: ILayer[] = responseData.layers
				.filter((layer: ILayer) => layer.name.startsWith('$'))
				.map((layer: ILayer) => ({
					name: layer.name,
					type: layer.type || 'image',
					visible: layer.visible,
					imageUrl: layer.imageUrl,
					content: layer.content,
				}));

			setLayers(mappedLayers);
		} catch (error) {
			console.error('Error fetching layers from API:', error);
		} finally {
			setLoadingLayers(false);
		}
	};

	useEffect(() => {
		if (selectedTemplate && !hasFetchedLayers) {
			fetchLayers();
		}
	}, [selectedTemplate?.variations]);

	useEffect(() => {
		if (industriesLoaded && templatesLoaded) {
			setIsOptionsReady(true);
		}
	}, [industriesLoaded, templatesLoaded]);

	useEffect(() => {
		if (user && user.isEmailVerified) {
			setIndustriesLoaded(false);
			setTemplatesLoaded(false);
			fetchIndustries();
			fetchTemplatesByScope(account?.industry);
		} else {
			setIndustries([]);
			setIndustriesLoaded(false);
			setTemplatesLoaded(false);
			setAccountTemplates([]);
			setPlatformTemplates([]);
			setStyles([]);
			setSeasons([]);
			setSelectedTemplate(null);
			setSelectedTemplateIds([]);
			setPayloads([]);
			setPayloadsByCaseState([]);
			setLayers([]);
			setConfiguredLayers([]);
		}
	}, [user?.id, user?.isEmailVerified, account?.id, account?.industry]);

	return (
		<TemplateContext.Provider
			value={{
				templates: accountTemplates,
				templatesByScope: platformTemplates,
				styles,
				seasons,
				industries,
				fetchIndustries,
				selectedIndustry,
				setSelectedIndustry,
				industriesLoaded,
				setIndustries,
				setIndustriesLoaded,
				setSelectTemplateModalOpen,
				selectedTemplate,
				setSelectedTemplate,
				selectedTemplateIds,
				setSelectedTemplateIds,
				fetchTemplatesByScope,
				payloads,
				templatesLoaded,
				isOptionsReady,
				setTemplatesLoaded,
				setPayloads,
				isSelectTemplateModalOpen,
				payloadsByCase,
				handleSetPayloadsByCase,
				setPayloadsByCaseState,
				resetSelectedTemplate,
				layers,
				setLayers,
				loadingLayers,
				configuredLayers,
				setConfiguredLayers,
			}}
		>
			{children}
		</TemplateContext.Provider>
	);
};

export default TemplateProvider;
