import React from 'react';
import { IMessage, IThread, IThreadPayload } from 'src/lib/schemas';

interface AssistantChatContextProps {
	isChatOpen: boolean;
	isMyAssistantWritting: boolean;
	isChatLoading: boolean;
	shouldSubmitBeAble: boolean | null;
	thread: IThread | null;
	openGeneralAssistantChat: (
		existingThreadId: string,
		language: string,
	) => Promise<void>;
	startNewConversation: (
		pathname: string,
		language?: string,
	) => Promise<string>;
	addMessage: (message: IMessage, threadId?: string) => void;
	sendMessage: (payload: IThreadPayload) => Promise<void>;
	handleAssistantChat: (pathname: string, language: string) => Promise<void>;
	addThread: (path: string, threadId: string) => void;
	getThread: (path: string) => string | undefined;
	removeThread: (path: string) => void;
	retryMessage: (message: IMessage) => void;
	imageCache: Set<string>;
}

const AssistantChatContext = React.createContext<AssistantChatContextProps>({
	isChatOpen: false,
	isMyAssistantWritting: false,
	isChatLoading: false,
	shouldSubmitBeAble: true,
	thread: null,
	openGeneralAssistantChat: async () => {},
	startNewConversation: async () => '',
	addMessage: () => {},
	sendMessage: async () => {},
	handleAssistantChat: async () => {},
	addThread: () => {},
	getThread: () => undefined,
	removeThread: () => {},
	retryMessage: () => {},
	imageCache: new Set(),
});

export default AssistantChatContext;
