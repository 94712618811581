import { FC, useState, useEffect } from 'react';
import { Box, Image as ChakraImage } from '@chakra-ui/react';

const DemoAssistantLogo: FC<{
	src?: string;
	size?: { h: number; w: number };
}> = ({ src, size = { h: 10, w: 10 } }) => {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		if (!src) return;

		const img = new Image();
		img.src = src;

		img.onload = () => setIsLoaded(true);

		return () => {
			img.onload = null;
		};
	}, [src]);

	return (
		<Box
			position="relative"
			height={size.h}
			width={size.w}
			borderRadius="full"
			overflow="hidden"
		>
			<ChakraImage
				src={src}
				alt="Demo User Logo"
				height={size.h}
				width={size.w}
				borderRadius="full"
				objectFit="cover"
				visibility={isLoaded ? 'visible' : 'hidden'}
			/>
		</Box>
	);
};

export default DemoAssistantLogo;
