import { createContext } from 'react';
import {
	IField,
	IMessage,
	IOpenAssistantChat,
	IThread,
	IThreadPayload,
} from 'src/lib/schemas';

interface IAssistantChatContext {
	isChatOpen: boolean;
	isChatCollapsed: boolean;
	field: IField | null;
	thread: IThread | null;
	isMyAssistantWritting: boolean;
	isChatLoading: boolean;
	shouldSubmitBeAble: boolean | null;
	requestMessages: IThreadPayload[];
	hasUnseenMessages: boolean;
	setField: (field: IField) => void;
	openChat: () => void;
	closeChat: () => void;
	setIsChatCollapsed: (value: boolean) => void;
	resetThread: () => void;
	addMessage: (message: IMessage, threadId?: string) => void;
	openAssistantChat: (params: IOpenAssistantChat) => void;
	openGeneralAssistantChat: (currentPath: string) => void;
	sendMessage: (payload: IThreadPayload) => void;
	applySuggestion: (value: string) => void;
	isCurrentChat: boolean;
	markMessageAsSeen: () => void;
	startNewConversation: () => void;
	retryMessage: (message: IMessage) => void;
	addThread: (path: string, threadId: string) => void;
	getThread: (path: string) => void;
	removeThread: (path: string) => void;
	clearThreads: () => void;
	handleAssistantChat: (pathname: string) => void;
}

const AssistantChatContext = createContext<IAssistantChatContext>({
	isChatOpen: true,
	isChatCollapsed: false,
	field: null,
	thread: null,
	isMyAssistantWritting: false,
	isChatLoading: false,
	shouldSubmitBeAble: true,
	requestMessages: [],
	hasUnseenMessages: false,
	setField: () => {},
	openChat: () => {},
	closeChat: () => {},
	setIsChatCollapsed: () => {},
	resetThread: () => {},
	addMessage: () => {},
	openAssistantChat: () => {},
	openGeneralAssistantChat: () => {},
	sendMessage: () => {},
	applySuggestion: () => {},
	isCurrentChat: true,
	markMessageAsSeen: () => {},
	startNewConversation: () => {},
	retryMessage: () => {},
	addThread: () => {},
	getThread: () => {},
	removeThread: () => {},
	clearThreads: () => {},
	handleAssistantChat: () => {},
});

export default AssistantChatContext;
