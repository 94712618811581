import getPublicRoutes from './getPublicRoutes';
import { IUser } from 'src/lib/schemas';

const getRoutes = (user: IUser | null) => [
	{
		path: '',
		children: [getPublicRoutes()],
	},
];

export default getRoutes;
