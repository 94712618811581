import http from 'src/services/http';

export const processCallback = async (callbackUrl: string): Promise<any> => {
	try {
		const response = await http.get(callbackUrl);
		const { status, body } = response.data;

		if (status === 'processing' || status === 'pending') {
			await new Promise((resolve) => setTimeout(resolve, 1000));
			return processCallback(callbackUrl);
		} else if (status === 'error' || status === 'failed') {
			return null;
		} else if (status === 'successful') {
			return body;
		}
	} catch (error) {
		console.error('Error in processCallback:', error);
		return null;
	}
};
