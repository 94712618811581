import { Box, Flex, useOutsideClick } from '@chakra-ui/react';
import { useContext, useEffect, useRef } from 'react';
import Header from './header';
import Messages from './messages';
import Actions from './actions';
import { CHANNELS_NAME_MAPPING } from 'src/lib/constants/campaign';
import AssistantChatContext from 'src/contexts/app/assistant-demo/AssistantChatContext';

const HEADER_HEIGHT = '50px';

const AssistantChat = () => {
	const { thread } = useContext(AssistantChatContext);
	const chatBoxRef = useRef<HTMLDivElement>(null);

	return (
		<Box
			ref={chatBoxRef}
			position="fixed"
			right={0}
			top={0}
			h={'100vh'}
			w={'100vw'}
			zIndex={2000}
			bg="white"
			overflow="hidden"
			boxShadow="lg"
			borderLeftWidth="1px"
			borderColor="#CDCDCD"
		>
			<Flex cursor={'default'} direction="column" h="full">
				<Header height={HEADER_HEIGHT} />
				<Messages conversation={thread?.conversation ?? []} />
				<Actions />
			</Flex>
		</Box>
	);
};

export default AssistantChat;
