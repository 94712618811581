import axios, { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import config from 'src/config';
import auth from './auth';
import { IAuthResponse } from 'src/lib/schemas';

const baseURL = config.app.apiURL.split('/v1')[0];

const axiosClient = axios.create({
	baseURL,
});

let refreshTokenPromise: Promise<IAuthResponse|undefined> | null = null

const setDefaultInterceptors = (axiosInstance: AxiosInstance) => {
	axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
		const tokens = auth.getTokens();

		if (tokens) {
			const expiresAt = new Date(tokens.refresh.expires);
			const now = new Date();
			if (now.getTime() > expiresAt.getTime()) {
				auth.cleanupSession();
				window.location.href = '/login';
			}

			config.headers.set('Authorization', `Bearer ${tokens.access.token}`);
			config.headers.set('Source', 'backoffice-fe');
		}

		if (config.data && !(config.data instanceof FormData)) {
			config.headers.set('Content-Type', 'application/json');
		}

		return config;
	});

	axiosInstance.interceptors.response.use(
		(response) => response,
		async (error) => {
			const { response, config } = error;
			
			if(response === undefined) {
					console.error("Error without response", error)
			} else {
				if (response.status >= 500) {
					// toastErrorGlobal(response);
				}

				if (response.status === 401 && config.url.includes('refresh-token')) {
					auth.cleanupSession();
					window.location.href = '/login';
				}

				if (response.status === 401 && config.url.includes('verify-email')) {
					window.location.href = '/pending-verification';
					return;
				}

				if (response.status === 401 && !config.sent) {
					
					if(refreshTokenPromise == null){
						config.sent = true;
						refreshTokenPromise = auth.refreshToken();
						
					}
					const refreshResponse = await refreshTokenPromise
					config.headers.set(
						'Authorization',
						`Bearer ${refreshResponse?.tokens.access.token}`,
					);

					setTimeout(function(){
						refreshTokenPromise = null
					},3000)
					return axiosClient(config)

					
				}
			} 

			return Promise.reject(error);
		},
	);
}

setDefaultInterceptors(axiosClient)

export default {
	get: axiosClient.get,
	post: axiosClient.post,
	put: axiosClient.put,
	patch: axiosClient.patch,
	delete: axiosClient.delete,
	setDefaultInterceptors:setDefaultInterceptors
};
